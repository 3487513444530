import React from "react"
import Layout from "../components/layout";
import { FadingImages } from "../components/FadingImages";
import styled from "@emotion/styled";
import { Container } from "@material-ui/core";
import logo from "../images/home/logo.png";
import typewriter1 from "../images/home/typewriter1.png";
import typewriter2 from "../images/home/typewriter2.png";
import typewriter3 from "../images/home/typewriter3.png";
import typewriter4 from "../images/home/typewriter4.png";
import { useTimeout } from "react-use";

const ImageWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 120px);
    display: flex;
    flex-direction: columns;
    align-items: center;
    img {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    img.phase1 {
      max-width: 700px;
    }
    img.phase2 {
      max-width: 600px;
    }
  `;

const images = [typewriter1, typewriter2, typewriter3, typewriter4];

const phase1ShowDuration = 3000;
const phase1FadeDuration = 2000;
const phase1TotalDuration = phase1ShowDuration + phase1FadeDuration;

const IndexPage = () => {

  const [timedOut] = useTimeout( phase1TotalDuration );
  const phase1 = !timedOut();

  return (
    <Layout>
      <Container maxWidth="md">
        <ImageWrapper>

          {phase1 && (
            <FadingImages
              images={[logo]}
              showDuration={phase1ShowDuration}
              fadeDuration={phase1FadeDuration}
              width={700}
              height={761}
              className={"phase1"}
            />
          )}

          {!phase1 && (
            <FadingImages
              images={images}
              width={600}
              height={500}
              className={"phase2"}
            />
          )}

        </ImageWrapper>
      </Container>
    </Layout>
  );

};

export default IndexPage
